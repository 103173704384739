import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import "../assets/Scss/templates/page.scss"

const page = ({ data }) => {
  const { page } = data
  const { title, content, databaseId, uri } = page


  return (
    <Layout
      bodyClass={`page-template-default page page-id-${databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <Seo title={title}  uri={uri} />

      <article
        className={`page-${databaseId} page type-page status-publish hentry container`}
        id={`page-${databaseId}`}
      >
        <header className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1
              className="entry-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </header>


        <div className="post-inner thin">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query page($id: String!) {
    page: customPage(id: { eq: $id }) {
      ...PageContent
    }
  }
`

export default page;
